import {objectMapValues} from "./object-utils";

export interface UiPartGroupDefinition<GROUP_NAME extends string> {
    __isUiPartGroup: true;
    groupName: GROUP_NAME;
}

const defaultUiPartController = uiPartCreateCustomController({
    nameAttribute: 'data-ui-part-name',
    idAttribute: 'data-ui-part-id',
    groupNameAttributePrefix: 'data-ui-parts-group-'
});

export const uiPartCreateMarking = defaultUiPartController.uiPartCreateMarking;
export const uiPartCreateSelector = defaultUiPartController.uiPartCreateSelector;
export const uiPartsGroupCreateMarking = defaultUiPartController.uiPartsGroupCreateMarking;
export const uiPartsGroupCreateSelector = defaultUiPartController.uiPartsGroupCreateSelector;

export function uiPartUnionSelectors (...selectors: string[]) {
    return selectors.join(',');
}

export function uiPartCreateNamespacedParts<T extends Record<string, string>> (
    namespace: string,
    parts: T
) {
    return objectMapValues(parts, partName => `${namespace}-${partName}`);
}

export function uiPartDefineGroups<T extends Record<any, string>> (
    groups: T
) : {
    [key in keyof T]: UiPartGroupDefinition<T[key]>
} {
    return objectMapValues(groups, value => {
        return {
            __isUiPartGroup: true,
            groupName: value
        }
    }) as any;
}

export function uiPartCreateCustomController(
    options: {
        nameAttribute: string;
        idAttribute: string;
        groupNameAttributePrefix: string;
    }
) {
    const {
        nameAttribute,
        idAttribute,
        groupNameAttributePrefix
    } = options;

    return {
        uiPartCreateMarking: (partName: string | number, distinguishingId?: string | number) => {
            const marking: Record<string, string | number> = {};
            marking[nameAttribute] = partName;
            if (distinguishingId) {
                marking[idAttribute] = distinguishingId;
            }

            return marking;
        },
        uiPartCreateSelector: (partName: string | number, distinguishingId?: string | number) => {
            return `[${nameAttribute}="${partName}"]` + (
                distinguishingId !== undefined ? `[${idAttribute}="${distinguishingId}"]` : ''
            );
        },
        uiPartsGroupCreateMarking: (groupDefinition: UiPartGroupDefinition<any>, partId?: string) => {
            return {
                [`${groupNameAttributePrefix}${groupDefinition.groupName}`]: partId ?? 'true'
            }
        },
        uiPartsGroupCreateSelector: (groupDefinition: UiPartGroupDefinition<any>, partId?: string) => {

            if (partId) {
                return `[${groupNameAttributePrefix}${groupDefinition.groupName}="${partId}"]`
            } else {
                return `[${groupNameAttributePrefix}${groupDefinition.groupName}]`
            }
        },
    };
}
