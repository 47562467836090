import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {WixImageResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {DoppeHideableValue, doppeHideableValueCreateHidden} from '../../client-server-common';
import {RichTextContent} from '@wix/devzai-utils-react';
import {immerProduce} from '@wix/devzai-utils-common';

export namespace DoppeActionTypeCode {

    export interface ActionSettings {
        media: WixImageResource[] | null;
        title: DoppeHideableValue<string>;
        description: DoppeHideableValue<RichTextContent>;
        code: string;
        buttonText: string;
        charsCount: number;
    }
}

export const doppeActionTypeCode = doppeActionTypeDefineMetadata<DoppeActionTypeCode.ActionSettings>({
    id: 'io.bymo.action/code',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        buttonText: '',
        media: null,
        title: doppeHideableValueCreateHidden(''),
        description: doppeHideableValueCreateHidden(''),
        code: '',
        charsCount: 6
    }),
    isWidgetOpener: true,
    isLinkOpener: false,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
    prepareForViewer: actionSettings => {
        actionSettings.code = '';
    },
    resolveUsedMedia: actionSettings => {
        return actionSettings.media ?? [];
    },
    resolveMainMedia: actionSettings => {
        return actionSettings.media?.[0] ?? null;
    },
    replaceExternalImages: (actionSettings, externalImagesUrlToImageResourceMap) => {
        return immerProduce(actionSettings, actionSettings => {
            if (actionSettings.media) {
                actionSettings.media = actionSettings.media.map(
                    image => wixMediaResourceReplaceExternalImage(image, externalImagesUrlToImageResourceMap))
            }
        })
    },
});
