import React from 'react';
import {doppeActionTypeDefineViewerMetadata} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {doppeActionTypeCode} from './doppe-action-type-code';

export const doppeActionTypeCodeViewer = doppeActionTypeDefineViewerMetadata(doppeActionTypeCode, {

    loadViewerComponents: async () => {
        return {
            DoppeActionTypeCodeWidget: (await import('./doppe-action-type-code-widget/doppe-action-type-code-widget')).DoppeActionTypeCodeWidget
        }
    },

    renderWidget: (renderProps, viewerComponents) => {

        const {
            DoppeActionTypeCodeWidget
        } = viewerComponents;

        return (
            <DoppeActionTypeCodeWidget
                {...renderProps}
            />
        )
    }
})