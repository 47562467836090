import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';

export namespace DoppeActionTypeWhatsappButton {

    export interface ActionSettings {
        phone: string;
        showContactPanel: boolean
    }
}

export const doppeActionTypeWhatsappButton = doppeActionTypeDefineMetadata<DoppeActionTypeWhatsappButton.ActionSettings>({
    id: 'io.bymo.action/whatsapp-button',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        phone: '',
        showContactPanel: false,
    }),
    isWidgetOpener: false
});
