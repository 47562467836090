import {Evaluable, evaluateFunction, evaluateWhenFunction} from '../evaluable';
import {
    arrayGenerate,
    EventEmitter,
    IDisposable,
    Promisable, Resolvable,
    skippableGetSkipToken,
    skippableIsNotSkipped,
    SkippableSkipToken
} from '..';
import PQueue from 'p-queue';

export class AsyncCancellationToken {

    private _isCanceled = false;
    private eventEmitter = new EventEmitter<{
        eventCanceled: void
    }>()

    public isCanceled () {
        return this._isCanceled;
    }

    public get eventCanceled() {
        return this.eventEmitter.createEventReference('eventCanceled')
    }

    cancel () {
        this._isCanceled = true;
        this.eventEmitter.emit('eventCanceled');
    }
}

export function asyncResolveAllInBackground (
    asyncCallbacks: Resolvable<any>[],
    options: {
        onError?: (error: any) => void;
        onSuccess?: () => void;
    } = {}
) {
    for (const asyncFunc of asyncCallbacks) {

        asyncExecuteInBackground(async () => {
            await evaluateWhenFunction(asyncFunc)
        }, options)
    }
}

export function asyncExecuteInBackground (
    asyncCallback: () => Promise<void>,
    options: {
        onError?: (error: any) => void;
        onSuccess?: () => void;
    } = {}
) {
    const {
        onError,
        onSuccess
    } = options;

    void asyncCallback().then(
        () => {
            onSuccess?.();
        },
        (error) => {
            onError?.(error);
        })
}

export function asyncCreateCancellationToken () {
    return new AsyncCancellationToken();
}

export function asyncCreateQueue (
    options: {
        concurrency: number;
    }
) {
    return new PQueue({
        autoStart: true,
        concurrency: options.concurrency
    })
}

export function asyncProcessPromisable<T, R> (
    promisable: Promisable<T>,
    processingFunction: (value: T) => R
) : Promisable<R> {
    return promisable instanceof Promise ?
        promisable.then(value => {
            return processingFunction(value)
        }).catch(error => {
            throw error;
        }) :
        processingFunction(promisable)
}

export function asyncDelay() : Promise<void>;
export function asyncDelay(timeout: number) : Promise<void>;
export function asyncDelay<T>(timeout: number, result: T) : Promise<T>;
export function asyncDelay<T>(timeout = 0, result?: T) {
    return new Promise<T | undefined>(resolve => {
        setTimeout(() => {
            resolve(result);
        }, timeout);
    });
}

const AbortToken: unique symbol = Symbol('AbortToken');
type AbortToken = typeof AbortToken;

export async function asyncTryWaitFor (
    pollingFunction: (abort: AbortToken) => Promise<boolean | AbortToken>,
    options: {
        interval: number;
        timeout?: number;
        onAttempt?: (attemptIndex: number) => void;
        onFailed?: (error: any) => void;
    }
) {
    try {
        await asyncWaitFor(pollingFunction, {
            interval: options.interval,
            timeout: options.timeout,
            onAttempt: options.onAttempt,
        })
    } catch (error) {
        options.onFailed?.(error);
    }
}

/**
 * @deprecated use asyncWithFallback instead.
 */
export async function asyncSafeAwait<T, D> (promise: Promise<T>, defaultValue: D) : Promise<T | D> {
    try {
        return await promise;
    } catch (error) {
        return defaultValue;
    }
}

export async function asyncWaitFor (
    pollingFunction: (abort: AbortToken, attemptIndex: number) => Promise<boolean | AbortToken>,
    options: {
        interval: number;
        timeout?: number;
        onAttempt?: (attemptIndex: number) => void;
        cancellationToken?: AsyncCancellationToken;
        maxAttemptsCount?: number;
    }
) {
    const {
        maxAttemptsCount = Infinity
    } = options;

    let attemptIndex = 0;

    await asyncPoll<void>(async (resolve, reject) => {

        options.onAttempt?.(attemptIndex);

        const result = await pollingFunction(AbortToken, attemptIndex);

        if (result === AbortToken) {
            reject(new Error(`Aborted`))
        }

        if (result) {
            resolve();
        }

        attemptIndex++;

        if (attemptIndex >= maxAttemptsCount) {
            throw new Error(`Max attempts`)
        }
    }, {
        interval: options.interval,
        timeout: options.timeout,
        cancellationToken: options.cancellationToken,
        rejectOnPollingError: false
    });
}

export class AsyncPollAbortedError extends Error {}

export async function asyncWaitForValue<T> (
    pollingFunction: (skip: SkippableSkipToken, attemptIndex: number, abortToken: AbortToken) => Promise<T | SkippableSkipToken | AbortToken>,
    options: {
        interval: number;
        timeout?: number | Promise<any>;
        cancellationToken?: AsyncCancellationToken;
        maxAttemptsCount?: number;
    }
) : Promise<T> {

    const maxAttemptsCount = options.maxAttemptsCount ?? Infinity;

    let attemptIndex = -1;

    return asyncPoll<T>(async (resolve, reject) => {
        attemptIndex++

        if (attemptIndex >= maxAttemptsCount) {
            reject(new AsyncPollAbortedError())
        } else {
            const result = await pollingFunction(skippableGetSkipToken(), attemptIndex, AbortToken);
            if (result === AbortToken) {
                reject(new AsyncPollAbortedError())
            } else if (skippableIsNotSkipped(result)) {
                resolve(result);
            }

            if (attemptIndex + 1 >= maxAttemptsCount) {
                reject(new AsyncPollAbortedError())
            }
        }
    }, {
        interval: options.interval,
        timeout: options.timeout,
        cancellationToken: options.cancellationToken,
        rejectOnPollingError: true
    });
}

export async function asyncPoll<T> (
    pollingFunc: (resolve: (result: T) => void, reject: (reason?: unknown) => void) => Promise<void>,
    options: {
        interval: number;
        timeout?: number | Promise<any>;
        rejectOnPollingError?: boolean;
        cancellationToken?: AsyncCancellationToken;
    }
) {

    const {
        interval,
        timeout,
        rejectOnPollingError = false,
        cancellationToken
    } = options;

    return new Promise<T>((resolve, reject) => {

        const pollingController = asyncStartPolling(
            async () => {
                await pollingFunc(
                    result => {
                        cancellationTokenBinding?.dispose();
                        if (pollingTimeout) {
                            clearTimeout(pollingTimeout);
                        }
                        pollingController.dispose();
                        resolve(result);
                    },
                    reason => {
                        cancellationTokenBinding?.dispose();
                        if (pollingTimeout) {
                            clearTimeout(pollingTimeout);
                        }
                        pollingController.dispose();
                        reject(reason);
                    })
            },
            interval,
            error => {
                if (rejectOnPollingError) {
                    pollingController.dispose();
                    reject(error);
                } else {
                    console.warn(`asyncPoll - uncaught error`, error);
                }
            });

        const cancellationTokenBinding = cancellationToken ?
            EventEmitter.one(cancellationToken.eventCanceled, () => {
                pollingController.dispose();
                if (pollingTimeout) {
                    clearTimeout(pollingTimeout);
                }
                reject(new Error('asyncPoll was canceled'));
            }) :
            undefined;

        const pollingTimeout =
            typeof timeout === 'number' ?
                setTimeout(() => {
                    cancellationTokenBinding?.dispose();
                    pollingController.dispose();
                    reject(new Error('asyncPoll was timed out'));
                }, timeout) :
                undefined;

        if (timeout !== undefined && typeof timeout !== 'number') {
            timeout.finally(() => {
                cancellationTokenBinding?.dispose();
                pollingController.dispose();
                reject(new Error('asyncPoll was timed out'));
            })
        }
    });
}

export function asyncStartPolling (
    pollingFunc: () => Promise<void>,
    interval: number,
    onPollingError?: (error: any) => void
) : IDisposable {

    let isDisposed = false;
    let timeoutId: any = undefined;

    const pollingFunction = async () => {
        try {
            await pollingFunc();
        } catch (error) {
            onPollingError?.(error);
        }

        if (!isDisposed) {
            timeoutId = setTimeout(() => {
                doPolling()
            }, interval)
        }
    }

    const doPolling = () => {
        pollingFunction().catch((error) => {
            console.warn(`asyncPoll - unexpected error was thrown. Probably 'onPollingError' callback threw an error`, error);
        });
    }

    doPolling();

    return {
        dispose () {
            isDisposed = true;
            clearTimeout(timeoutId);
        }
    }
}

export async function asyncForEachSequentially<T> (iterable: Iterable<T>, iterator: (value: T, index: number) => Promise<void>) {
    let index = 0;
    for (const value of iterable) {
        await iterator(value, index);

        index++;
    }
}

export function asyncForEach<T> (
    iterable: Iterable<T>,
    iterator: (value: T, index: number) => Promise<void>,
    pQueue?: PQueue
) {

    const promises = [] as Promise<void>[];

    let index = 0;
    for (const value of iterable) {
        promises.push(
            pQueue !== undefined ?
                pQueue.add(((value, index) => {
                    return () => {
                        return iterator(value, index)
                    }
                })(value, index)) :
                iterator(value, index));

        index++;
    }

    return Promise.all(promises);
}

export async function asyncAny<T> (iterable: Iterable<T>, predicate: (value: T) => Promise<boolean>) {

    const promises = [] as Promise<boolean>[];

    for (const value of iterable) {
        promises.push(predicate(value));
    }

    const predicatesResult = await Promise.all(promises);

    return predicatesResult.includes(true);
}

const SkipToken: unique symbol = Symbol('SkipToken');

function isSkipToken<V>(value: V | typeof SkipToken) : value is V {
    return value !== SkipToken;
}

export async function asyncGenerateArray<T> (count: number, generator: (index: number) => Promise<T>) {
    return Promise.all(arrayGenerate(count, generator))
}

export async function asyncMapToArraySequentially<T, V> (
    iterable: Iterable<T>,
    mappingFunction: (value: T, skip: typeof SkipToken, index: number) => Promise<V | typeof SkipToken>
) : Promise<V[]> {
    const result = [] as V[];

    let index = 0;
    for (const value of iterable) {
        const mappingResult = await mappingFunction(value, SkipToken, index);

        if (mappingResult !== SkipToken) {
            result.push(mappingResult);
        }

        index++;
    }

    return result;
}

export async function asyncMapToArray<T, V> (
    iterable: Iterable<T>,
    iterator: (value: T, skip: typeof SkipToken, index: number) => Promise<V | typeof SkipToken>,
    parallelism?: number | PQueue
) : Promise<V[]>{

    if (parallelism !== undefined) {

        const pQueue = typeof parallelism === 'number' ? asyncCreateQueue({concurrency: 2}) : parallelism;

        const valuesArr = [...iterable].map((value, index) => {
            return {
                value: value,
                index: index
            }
        });

        const results: (V | typeof SkipToken)[] = [];

        await asyncForEach(valuesArr, async handledValue => {
            results[handledValue.index] = await iterator(handledValue.value, SkipToken, handledValue.index)
        }, pQueue);

        return results.filter(isSkipToken);

    } else {
        const promises = [] as Promise<V | typeof SkipToken>[];

        let i = 0;
        for (const value of iterable) {
            promises.push(iterator(value, SkipToken, i));
            i++;
        }

        const results = await Promise.all(promises);

        return results.filter(isSkipToken);
    }
}

export async function asyncAll<T extends Record<string, any>> (
    promises: {[key in keyof T]: Promisable<T[key]> | (() => Promise<T[key]>)}
) : Promise<T> {
    const result: Record<string, any> = {};

    await Promise.all(Object.entries(promises).map(async ([key, promise]) => {
        result[key] = typeof promise === 'function' ? await promise() : await promise;
    }));

    return result as T;
}

export async function asyncSafeInvoke (asyncFunc: Evaluable<() => Promise<void>>, errorCallback?: (error: unknown) => void) : Promise<void> {
    try {
        await evaluateWhenFunction(asyncFunc)
    } catch (error) {
        errorCallback?.(error)
    }
}


export async function asyncWithFallback<R, F> (
    asyncFunc: Evaluable<() => Promise<R>>,
    fallbackValue: F,
    options: {
        errorCallback?: (error: unknown) => void;
    } = {}
) : Promise<R | F> {
    try {
        return await evaluateWhenFunction(asyncFunc)
    } catch (error) {

        try {
            options.errorCallback?.(error);
        } catch (_error) {
            // Do nothing
        }

        return fallbackValue;
    }
}

export async function asyncWithTimeout<R> (
    asyncFunc: Evaluable<() => Promise<R>>,
    timeout: number
) {
    return Promise.race([
        evaluateWhenFunction(asyncFunc),
        evaluateFunction(async () => {
            await asyncDelay(timeout);
            throw new Error(`Timed out in ${timeout}ms`)
        })
    ])
}

class ExpectedToBeRejectedError extends Error {}

export async function asyncRejectIfResolved (
    value: Evaluable<() => Promisable<any>>,
    options: {
        rejectCallback?: (error: any) => void;
    } = {}
) {

    try {
        await evaluateWhenFunction(value);

        throw new ExpectedToBeRejectedError();
    } catch (error) {
        if (error instanceof ExpectedToBeRejectedError) {
            throw error;
        }

        options.rejectCallback?.(error);
    }
}

export async function asyncWithRetries<R> (
    asyncFunc: (retry: number) => Promise<R>,
    options: {
        /**
         * Note: This is actually the number of attempts and not the number of retries.
         */
        retries: number;
        interval: number | ((retry: number) => number);
        onAttempt?: (attempt: number) => void;
        /**
         * Called for every errored attempt, and can return an AbortToken to stop retries.
         */
        onAttemptFailure?: (error: unknown, abortToken: AbortToken, attempt: number) => void | AbortToken;
    }
) : Promise<R> {

    const {
        retries,
        interval,
        onAttempt,
        onAttemptFailure
    } = options;

    for (let i = 0; i < retries; i++) {

        try {
            onAttempt?.(i);
        } catch (error) { /* Do Nothing */ }

        try {
            return await asyncFunc(i);
        } catch (error) {

            const attemptFailureResult = evaluateFunction(() => {
                // We ignore potential errors during onAttemptFailure execution.
                try {
                    return onAttemptFailure?.(error, AbortToken, i);
                } catch (error) {
                    return undefined;
                }
            })

            if (attemptFailureResult === AbortToken || i === retries - 1) {
                throw error;
            }
        }

        await asyncDelay(evaluateWhenFunction(interval, i));
    }

    throw new Error(`Unreachable Code Invariant`)
}

export class Deferred<T> {
    public readonly promise: Promise<T>;
    private _resolve?: (value: T | PromiseLike<T>) => void;
    private _reject?: (reason?: any) => void;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }

    resolve(value: T | PromiseLike<T>) {
        this._resolve?.(value);
    }

    reject(reason?: any) {
        this._reject?.(reason);
    }
}

const infiniteDeferred = new Deferred<any>();

export function asyncCreateInfinitelyPendingPromise<T = any> () : Promise<T> {
    return infiniteDeferred.promise;
}