import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';
import {Values} from '@wix/devzai-utils-common';


export const TwitterWidgetTheme = {
    Light: 'light',
    Dark: 'dark',
} as const;

export namespace DoppeActionTypeTwitterEmbed {

    export type Theme = Values<typeof TwitterWidgetTheme>;

    export interface ActionSettings {
        twitterUrl: string;
        theme: Theme;
    }
}

export const doppeActionTypeTwitterEmbed = doppeActionTypeDefineMetadata<DoppeActionTypeTwitterEmbed.ActionSettings>({
    id: 'io.bymo.action/twitter-embed',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        twitterUrl: '',
        theme: TwitterWidgetTheme.Light,
    }),
    hasNoGoalMeasuring: false,
    isWidgetOpener: true,
    canRenderInPage: true,
    skipReportingOnGoalAchievedOnActionButtonClick: true,
})

