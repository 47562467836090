import {
    doppeActionTypeCreateSettingsResolvingFunction,
    doppeActionTypeDefineMetadata
} from '../../client-server-common/doppe-action-types/doppe-action-type';

export namespace DoppeActionTypeNavigateButton {


    export interface ActionSettings {
        url: string;
        showContactPanel: boolean
    }
}

export const doppeActionTypeTelegramButton = doppeActionTypeDefineMetadata<DoppeActionTypeNavigateButton.ActionSettings>({
    id: 'io.bymo.action/telegram-button',
    resolveActionSettings: doppeActionTypeCreateSettingsResolvingFunction({
        url: '',
        showContactPanel: false,
    }),
    isWidgetOpener: false
});
